"use client";
import { useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  List,
  Stack,
  Typography
} from "@mui/material";
import {
  LogoutOutlined as SignOutIcon,
  Settings as SettingsIcon,
  Close as CloseIcon
} from "@mui/icons-material";
import AnonymousIcon from "mdi-react/IncognitoIcon";

import { useStore, observer } from "../../service/mobx";
import ToolTip from "../Tooltip";
import NextAvatar from ".";
import useSignInDialog from "../Dialog/dialogs/appWide/Login";
import Popup from "../Popup";
import { ListItem } from "../List/ListItem";

function LoginUserAvatar(props) {
  const [open, setOpen] = useState(false);
  const { bottomSheet, device, user } = useStore();
  const openDialog = useSignInDialog();

  return (
    <ToolTip title={user.isAnonymous ? "Sign in" : "User menu"}>
      <Box
        sx={{ cursor: "pointer" }}
        onClick={
          user.loaded
            ? () => {
                if (user.isAnonymous) {
                  openDialog();
                } else {
                  setOpen(!open);

                  if (device.isPhone) {
                    bottomSheet.set.onClose(() => {
                      setOpen(false);
                      bottomSheet.set.open();
                    });
                  }
                }
              }
            : undefined
        }
      >
        <Popup
          controlled
          open={open}
          placement="right"
          eventName="User Menu"
          disable={user.isAnonymous}
          Component={() => <UserMenu setOpen={setOpen} />}
          modifiers={[{ name: "offset", options: { offset: [8, 26] } }]}
        >
          <UserAvatar {...props} />
        </Popup>
      </Box>
    </ToolTip>
  );
}

export default observer(LoginUserAvatar);

export const UserAvatar = observer(function UserAvatar(props) {
  const { user } = useStore();

  return (
    <NextAvatar
      key={user.photo}
      src={user.photo}
      alt={user.name}
      sx={{
        "& svg": {
          color: "surface.on.variant"
        }
      }}
      fallback={<AnonymousIcon />}
      {...props}
    />
  );
});

const UserMenu = observer(function UserMenu({ setOpen }) {
  const { bottomSheet, device, user } = useStore();
  const closePopup = () => setOpen(false);

  return (
    <Stack
      p={{ compact: 0, expanded: 1 }}
      // bgcolor="surface.container.low"
      bgcolor="surface.container.low"
      // sx={theme => ({ borderRadius: theme.shape.xl.round })}
    >
      {device.isPhone ? null : (
        <IconButton
          onClick={closePopup}
          sx={{ alignSelf: "flex-end", color: "surface.on.variant" }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Stack
        useFlexGap
        spacing={1}
        p={0}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="labelMd" color="surface.on.color">
          {user.session.email}
        </Typography>
        <div>
          <UserAvatar size={56} />
        </div>
        <Typography
          variant="titleMd"
          color="surface.on.color"
          textTransform="capitalize"
        >
          Hello {user.name?.split(" ")[0]}
        </Typography>
      </Stack>
      <Divider sx={{ pt: 2, borderColor: "outline.variant" }} />
      <List dense>
        {[
          { line1: "Settings", Icon: SettingsIcon, href: "/settings" },
          { line1: "Sign out", Icon: SignOutIcon, onClick: user.sign.out }
        ].map(({ line1, href, Icon, onClick }, index) => (
          <ListItem
            key={index}
            href={href}
            line1={line1}
            onClick={() => {
              if (bottomSheet.open) {
                bottomSheet.set.open(false);
              }

              closePopup();
              onClick?.();
            }}
            trailing={{ Icon }}
          />
        ))}
      </List>
    </Stack>
  );
});
